import React from "react";
import Accordion from "../components/modules/Accordion";

const OurServices = ({ showTitle = true }) => {
  const accordionItems = [
    {
      id: "p1",
      title: "App Design & Development",
      text: "From concept to launch we have the experience to realise your app aspiration. Let us show you how we have helped other companies take an idea and turn it into business value.",
    },
    {
      id: "p2",
      title: "Full Stack Development",
      text: "If you’ve got a product you’d like to build, we can supply all development services to get you to release. We’ll do the development and deployment so that you can take care of your business, from rapid global releases to small scale deployments.",
    },
    { 
      id: "p3", 
      title: "Hybrid App Development & Redevelopment", 
      text: "Existing web-based app, or something completely new to capture more customers, we can work with you to manage the full design, build and deployment of your new app." 
    },
    { 
      id: "p4", 
      title: "App Infrastructure Management & Support ", 
      text: "App reliability is critical, and where it lives underpins this, we have the experience to ensure your customers always have the best user experience." 
    },
  ];

  return (
    <Accordion
      title={showTitle && "Our Services"}
      color="yellow"
      items={accordionItems}
      btnText={showTitle && "View Our Services"}
      btnTo={showTitle && "/our-services"}
    />
  );
};

export default OurServices;
