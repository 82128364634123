import React from "react";

const Welcome = () => {
  return (
    <section className="bg-primaryBlack">
      <div className="max-width welcome-box flex flex-row items-start text-4xl xl:text-6xl text-white select-none overflow-x-hidden">
        <div style={{fontWeight: 100, color: '#3b3b3b'}} className="select-none text-gray-600 h-full number-column hidden mx-12 md:flex flex-col justify-between cool-sans">
          <div data-nosnippet>16</div>
          <div data-nosnippet>17</div>
          <div data-nosnippet>18</div>
          <div data-nosnippet>19</div>
          <div data-nosnippet>20</div>
          <div data-nosnippet>21</div>
          <div data-nosnippet>22</div>
          <div data-nosnippet>23</div>
          <div data-nosnippet>24</div>
          <div data-nosnippet>25</div>
          <div data-nosnippet>26</div>
          <div data-nosnippet>27</div>
        </div>
        <div style={{fontWeight: 100, color: '#3b3b3b'}} className="text-gray-600 h-full w-full md:ml-16 flex flex-col justify-center md:justify-between cool-sans">
          <div data-nosnippet>
            const WhoWeAre = () ={">"} {"{"}
          </div>
          <div className="ml-5" data-nosnippet>return (</div>
          <div className="ml-10 tag"></div>
          <div className="ml-16 tag-h2" data-nosnippet>UK Based Team</div>
          <div className="ml-20 whitespace-nowrap tag-p" data-nosnippet>Passionate about your projects</div>
          <h1>
            <div className="text-primaryMagenta text-center md:inline-flex md:ml-24 tag">
              <span className="text-white">We empower&nbsp;</span>
            </div>
            <br className="hidden md:inline-flex" />
            <div className="text-primaryMagenta text-center md:inline-flex md:ml-24 tag">
              <span className="text-white">Businesses with code&nbsp;</span>
            </div>
          </h1>
          <div className="ml-20 tag-h2">Using React, Laravel, Python, &amp; Terraform</div>
          <div className="ml-16 tag-p">On AWS, Microsoft 365, Salesforce &amp; Cloudflare</div>
          <div className="ml-10 tag" data-nosnippet></div>
          <div className="ml-5" data-nosnippet> )</div>
          <div data-nosnippet>{"}"}</div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
