import React from "react";
import Button from "../../components/ui/Button";
import moduleColors from "../../components/modules/modules-colors";
import LeftArrows from "../../images/textImage/left-arrow-group.svg";
import FeatureArrows from "../../images/textImage/right-arrow-group.svg";

const Challenging = (children) => {
  // Class width and layout
  const sectionClass =
    "py-16 px-12 grid grid-cols-1 lg:grid-cols-2 gap-3 items-center max-width ";
  const { bgColor, btnColor } = moduleColors(children.color);

  return (
    <section className={bgColor}>
      <div className={sectionClass}>
        <article>
          <h2 className="text-3xl md:text-5xl font-thin mb-8">
            We're <span className="text-primaryMagenta"> challenging </span>
            the development agency landscape
          </h2>
          <p className="text-xl md:text-xl mb-8 w-full">
          Whilst traditional agencies often supply one solution platform 
          for all requirements and build on top of that, we make it our 
          mission to understand your specific requirements to recommend 
          the best technologies for you. Every one of our solutions is 
          bespoke.
          </p>
          <div className="w-max">
            <Button color={btnColor} to="/our-approach">
              Our Approach
            </Button>
          </div>
        </article>

        <div className="p-2 w-full flex flex-col justify-center items-center">
          <img className="h-36 hidden lg:inline-flex" src={LeftArrows} alt="" />
          <img className="h-36" src={FeatureArrows} alt="" />
          <img className="h-36 hidden lg:inline-flex" src={LeftArrows} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Challenging;
