import React from "react";
import Editorial from "../../components/modules/Editorial";

const OurProcess = () => {
  const editorialItems = [
    {
      id: "e1",
      title: "Discovery",
      text: "Working hand in hand with you to validate the business and technical design of the application.",
    },
    {
      id: "e2",
      title: "Structure",
      text: "Using the discovery documentation, we’ll breakdown all features into workable feature milestones.",
    },
    {
      id: "e3",
      title: "Development",
      text: "Our team will be unleashed on your project, working in an agile workflow we’ll keep you updated with feature previews.",
    },
    {
      id: "e4",
      title: "Testing",
      text: "Our testing loop ensures quality using milestones set out during structuring, this way we can get to agreed sign-off.",
    },
    { 
      id: "e5", 
      title: "Deployment", 
      text: "We’ll bring your project to life providing a documented pre-production and production deployment plan for your application." 
    },
    {
      id: "e6",
      title: "Maintenance",
      text: "We’re in it for the long haul, we’ll create a maintenance plan and schedule tasks to keep dependencies up to date.",
    },
  ];
  return (
    <Editorial
      title="Our Process"
      color="black"
      items={editorialItems}
      showCount={true}
    />
  );
};

export default OurProcess;
