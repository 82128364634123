import React from "react";
import Layout from "../components/layout/Layout";

// Page Content
import Welcome from "../content/home/Welcome";
// import OpeningText from "../content/home/OpeningText";

import Challenging from "../content/home/Challenging";
import OurServices from "../content/OurServices";
import OurProcess from "../content/home/OurProcess";
import OurTeam from "../content/OurTeam";
import Testimonials from "../components/modules/Testimonials";

const index = () => {
  return (
    <Layout isHome={true} title="Home">
      <Welcome />
      <Challenging color="white" />
      {/* <OpeningText /> */}
      <OurServices />
      <OurProcess />
      <OurTeam />
      <Testimonials />
    </Layout>
  );
};

export default index;
